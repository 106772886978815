import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Box,
  Drawer,
  IconButton,
  List, ListItem,
  ListItemButton, ListItemIcon, ListItemText,
  Stack, Toolbar,
  Typography
} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import {
  ChevronLeft,
  ChevronRight,
  MenuOpen, OpenInNew,
  PlayArrow
} from "@mui/icons-material";
import axios from "../../axios/axios";
import {teal} from "@mui/material/colors";

const drawerWidth = 330

export const MainLayout = ({children, primary, secondary}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [chats, setChats] = useState([]);

  const headerRef = useRef();

  useEffect(() => {
    axios.get('/api/session')
      .then(response => {
        setChats(response?.data)
      })
      .catch((error) => console.log(error?.message))
  }, [])

  const height = useMemo(() => {
    let val = 0;
    if (headerRef.current) {
      val = headerRef.current.offsetHeight || 0;
    }
    return val;
  }, [headerRef.current])

  const sidebarContent = useMemo(() => {
    return (
      <Box sx={{p: 1, width: '100%', overflowY: "auto"}}>
        <List>
          <ListItemButton
            component={Link}
            to={"/"}
            sx={{
              color: "white",
              borderRadius: 3,
              mb: 1,
              py: 0.5,
              background: "rgba(132, 145, 247, 0.8)",
              "&:hover": {
                background: "rgba(132, 145, 247, 1)"
              }
            }}
          >
            <ListItemIcon><PlayArrow style={{color: "#FFF", fontSize: 18}}/></ListItemIcon>
            <ListItemText primary={"Start new conversation"} primaryTypographyProps={{fontSize: 15}}/>
          </ListItemButton>
          <ListItem>
            <ListItemText primary={"History"} primaryTypographyProps={{fontWeight: 800}}/>
          </ListItem>
          {
            chats?.map(item => (
              <ListItemButton
                key={item?.id}
                sx={{"&.active": {bgcolor: `${teal[50]} !important`}, borderRadius: 3}}
                component={NavLink} to={`/chat/${item?.id}`}
              >
                <ListItemIcon sx={{fontSize: 14}}>
                  {item.id}.
                </ListItemIcon>
                <ListItemText
                  primary={item?.name}
                  primaryTypographyProps={{fontSize: 14}}
                />
              </ListItemButton>
            ))
          }
        </List>
      </Box>
    )
  }, [chats])

  return <>
    <Toolbar id={"header"} ref={headerRef} sx={{bgcolor: "#FFF"}}>
      <Stack direction={"row"}
             sx={{width: "100%", pt: 1}} gap={1}
             justifyContent={"space-between"}
             alignItems={"center"}>
        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} gap={1}>
          <Stack direction={"row"}
                 sx={{width: "100%"}}
                 justifyContent={isOpen ? "space-between" : "center"}
                 alignItems={"center"}>
            <img src={'/logo.svg'} alt={"Logo"} width={40} height={"auto"}/>
            {/*<Box sx={{px: 1, display: {xs: 'none', sm: "block", color: "primary.dark"}}}>*/}
            {/*  <Typography fontSize={15} fontWeight={900} color={"dark.main"}>OpenBrain</Typography>*/}
            {/*  <Typography fontSize={8} textTransform={"uppercase"} color={"dark.main"} fontWeight={"bold"}>voice bot</Typography>*/}
            {/*</Box>*/}

            {/*<IconButton onClick={() => setIsOpen(!isOpen)}>*/}
            {/*  <Menu/>*/}
            {/*</IconButton>*/}
          </Stack>
          {/*<UserInfo />*/}
        </Stack>

        <Box sx={{display: {xs: 'none', sm: "flex"}}}>
          {primary}
        </Box>

        <Stack direction={"row"} gap={2} alignItems={"center"}>
          {secondary}
          {window.location !== window.parent.location && <IconButton
            component={"a"}
            href={`${window.location}?json_url=${localStorage.getItem("json_url")}`}
            target={"_blank"}
          >
            <OpenInNew fintSize={"small"}/>
          </IconButton>}
        </Stack>
      </Stack>
    </Toolbar>
    <Stack direction="row" sx={{height: `calc(100% - ${height}px)`, width: '100vw'}}>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        variant={"temporary"}
        sx={{
          width: `${drawerWidth}px !important`,
          border: 0,
          height: "100%",
          overflowY: "auto",
          boxShadow: 0,
          py: 1,
          px: 2,
          zIndex: 100000,
          display: {xs: "flex", md: "none"},
          '& .MuiDrawer-paper': {
            border: 0,
            boxShadow: 0,
            zIndex: 100000,
            width: `${drawerWidth}px !important`,
          },
        }}
      >
        <Toolbar>
          <Stack direction={"row"}
                 sx={{width: "100%"}}
                 justifyContent={isOpen ? "space-between" : "center"}
                 alignItems={"center"}>
            <img src={"/logo.svg"} alt={"Logo"} width={40} height={"auto"}/>
            <Box sx={{px: 1, display: {xs: 'none', sm: "block"}}}>
              <Typography fontSize={15}>OpenBrain</Typography>
              <Typography fontSize={8} textTransform={"uppercase"} fontWeight={"bold"}>voice
                bot</Typography>
            </Box>

            <IconButton onClick={() => setIsOpen(!isOpen)}>
              <MenuOpen/>
            </IconButton>
          </Stack>
        </Toolbar>
        {sidebarContent}
      </Drawer>
      {isOpen && <Box
        sx={{
          flex: 1,
          display: {xs: "none", md: "flex"},
          minWidth: '260px'
        }}
      >
        {sidebarContent}
      </Box>}
      <Box sx={{
        maxHeight: `calc(100vh - ${height}px)`,
        height: "100%",
        minWidth: '300px',
        flex: 4,
        position: "relative",
        overflowY: "auto"
      }}>

        <IconButton
          size={"small"}
          sx={{
            position: "absolute",
            top: "50%",
            left: 10,
            zIndex: 1000,
            transform: 'translate(0%, -50%)',
            p: 0.5
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <ChevronLeft size={15}/> : <ChevronRight size={15}/>}
        </IconButton>
        {children}
      </Box>
    </Stack>
  </>
}
