import {MainLayout} from "../../layouts/main/MainLayout";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import React, {useEffect} from "react";
import axios from "../../axios/axios";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      onCreateChat();
    }, [1500]);

    return () => clearTimeout(timeout);
  }, []);

  const onCreateChat = async () => {
    try {
      const response = await axios.post(`/api/session`, {json: localStorage.getItem("json_url") || null})
      navigate(`/chat/${response?.data?.id}`);
    } catch (e) {
      toast.error(e.message);
      console.log(e)
    }
  }

  return (
    <MainLayout>
      <Box sx={{position: "relative", width: `100%`, height: "100%", p: 1}}>
        <Box className={"blured"}>
          <Stack direction={"column"} gap={2}
                 justifyContent={"center"} alignItems={"center"}
                 maxWidth={"sm"}
                 sx={{width: "100%", height: `calc(100% - 80px)`, m: 'auto', p: 3, textAlign: 'center'}}>

              <Typography variant={"h6"}>
                Just Brewing Some Chat Magic: Hang Tight!
              </Typography>
              <Typography sx={{fontSize: 12, color: grey[500], mb: 1}}>
                Hang tight! Our chat feature is gearing up to connect you with friends, colleagues, and more. Your wait will soon be over as we fine-tune the experience for seamless interactions. Thanks for your patience!
              </Typography>
              <CircularProgress size={20} />
          </Stack>
        </Box>
      </Box>
    </MainLayout>
  )
}