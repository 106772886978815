import { useState, useEffect } from 'react';
import useDebounce from "./useDebounce";

const useSpaceHold = () => {
  const [isSpaceHeld, setIsSpaceHeld] = useState(false);
  const [isHolding] = useDebounce(isSpaceHeld, 500)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        setIsSpaceHeld(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.code === 'Space') {
        setIsSpaceHeld(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return isHolding;
};

export default useSpaceHold;
