import React, {useEffect, useState} from 'react';

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [counter, setCounter] = useState(delay / 1000);

  useEffect(
    () => {
      // Update debounced value after delay
      if (value === debouncedValue) {
        return ;
      }
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setCounter(delay / 1000);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  useEffect(() => {
    const counterHandler = setTimeout(() => {
      setCounter(prev => prev > 0 ? prev - 1 : 0);
    }, 1000)
    return () => {
      clearInterval(counterHandler);
    }
  }, [debouncedValue, counter])

  return [debouncedValue, counter];
};

export default useDebounce;
