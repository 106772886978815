import React, {useEffect, useState} from "react";
import {Box, Drawer, IconButton, Stack, Toolbar, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ChevronLeft, ChevronRight, MenuOpen} from "@mui/icons-material";
import axios from "../../axios/axios";
import ReactJson from "react-json-view";
import logo from "../../assets/logo.svg";


export const DebugMessage = ({selectedMessage, messages}) => {
  const [open, setOpen] = useState();
  const [log, setLog] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (selectedMessage && selectedMessage?.messageId) {
      axios.get(`/api/message/${selectedMessage?.messageId}/log`)
        .then(response => {
          setLog(response.data);
        })
        .catch((error) => setLog({body: `{"error": "${error?.message}"}`}));
      if (matches) {
        setOpen(true);
      }
    }
  }, [selectedMessage])

  useEffect(() => {
    if (messages?.length && !selectedMessage && open) {
      const message = messages[messages.length - 1];
      if (message && message?.messageId) {
        axios.get(`/api/message/${message?.messageId}/log`)
          .then(response => {
            setLog(response.data);
          })
          .catch((error) => setLog({body: `{"error": "${error?.message}"}`}));
      }
    }
  }, [messages, open])

  const content = (
    log && <Box sx={{maxHeight: "calc(100dvh - 60px)", height: '100%', flex: 1, overflowY: "auto"}}>
      <ReactJson src={JSON.parse(log?.body)}
                 indentWidth={2} groupArraysAfterLength={3}
                 enableClipboard={false} displayDataTypes={false} displayObjectSize={false}
                 theme={"bright:inverted"} style={{height: "100%", width: "300px"}}/>
    </Box>
  )


  return (
    <>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        variant={"temporary"}
        anchor={"right"}
        sx={{
          width: {xs: '100%', md: `330px !important`},
          border: 0,
          height: "100%",
          overflowY: "auto",
          boxShadow: 0,
          py: 1,
          px: 2,
          zIndex: 100000000,
          display: {xs: "flex", md: "none"},
          '& .MuiDrawer-paper': {
            border: 0,
            boxShadow: 0,
            zIndex: 100000,
            width: {xs: '100%', md: `330px !important`},
          },
        }}
      >
        <Toolbar>
          <Stack direction={"row"}
                 sx={{width: "100%"}}
                 justifyContent={open ? "space-between" : "center"}
                 alignItems={"center"}>
            <img src={"/logo.svg"} alt={"Logo"} width={50} height={"auto"}/>
            <Box sx={{px: 1}}>
              <Typography fontSize={15}>Debug</Typography>
            </Box>

            <IconButton onClick={() => setOpen(!open)}>
              <MenuOpen/>
            </IconButton>
          </Stack>
        </Toolbar>
        {content}
      </Drawer>
      <Tooltip title={"Debug"}>
        <IconButton
          size={"small"}
          sx={{
            display: {xs: "block", md: "none"},
            position: "absolute",
            top: "50%",
            right: 10,
            zIndex: 1000,
            transform: 'translate(0%, -30%)',
            p: 0.5

          }}
          onClick={() => setOpen(!open)}
        >
          {open ? <ChevronRight size={15}/> : <ChevronLeft size={15}/>}
        </IconButton>
      </Tooltip>

      <Stack direction={"row"} alignItems={"center"}
             sx={{height: "100%", maxWidth: "330px", px: 1, display: {xs: 'none', md: "flex"}}}>
        <Tooltip title={"Debug"}>
          <IconButton
            size={"small"}
            sx={{
              zIndex: 1000,
              p: 0.5
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronRight size={15}/> : <ChevronLeft size={15}/>}
          </IconButton>
        </Tooltip>
        {open && log && content}
      </Stack>
    </>
  )
}