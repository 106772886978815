import {useEffect, useMemo, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import {PlayArrow, Stop, VolumeMute, VolumeUp} from "@mui/icons-material";
import axios from "../../axios/axios";

export const AudioPlayer = ({messages, selectedMessage, isPaused, session, setSession}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const audioRef = useRef();

  useEffect(() => {
    if (session) {
      setIsMuted(session ? !session?.audio : true);
    }
  }, [session])

  useEffect(() => {
    if (!audioRef.current?.paused && (isMuted || !isPaused)) {
      forceStop();
    }
  }, [isMuted, isPaused])

  useEffect(() => {
    if (messages.length && audioRef?.current?.paused && !isMuted) {
      const index = messages.length - 1;
      if (currentIndex !== index) {
        setCurrentIndex(index);
      } else {
        const message = messages[index];
        playAudio(message?.audio || null);
      }
    }
  }, [messages, isMuted])

  useEffect(() => {
    const message = messages[currentIndex];
    if (message) {
      playAudio(message?.audio || null);
    }
  }, [currentIndex])

  useEffect(() => {
    if (selectedMessage) {
      const index = messages?.findIndex(msg => msg.id === selectedMessage.id);
      setCurrentIndex(index);
    }
  }, [selectedMessage])

  const playAudio = (audio) => {
    if (audio) {
      audioRef.current.src = audio;
    }
  }

  const forceStop = () => {
    audioRef.current.currentTime = 0;
    audioRef.current?.pause();
  }

  const onPlayNext = () => {
    if (currentIndex < messages.length && !selectedMessage) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const onToggleAudio = () => {
    if (session) {
      axios.patch(`/api/session/audio/${session?.id}`)
        .then(response => {
          setSession(response?.data);
          setIsMuted(!response?.data?.audio)
        })
        .catch((error) => console.log(error?.message))
    }
  }

  return (
    <>
      <audio
        ref={audioRef}
        autoPlay
        muted={isMuted}
        onEnded={onPlayNext}
        onLoadedData={() => audioRef?.current?.play()}
      />
      <IconButton onClick={onToggleAudio}>
        {isMuted ? <VolumeMute/> : <VolumeUp/>}
      </IconButton>
    </>
  )
}